import { IOltAppSettingResult } from "@outerlimitstech/ngx-app-core";
import { IAppSettings } from "bgcslib";
import { environment } from "environments/environment";

export const CONSTANTS = {
  Storage: {
    Test: 'bgcs.online-test',
    FeatureFlags: 'bgcs.portal.feature.flags'
  },
  TestActions: {
    start: 'start',
    available: 'available',
    completed: 'completed'
  },
  Application: {
    Name: 'bgcs-indiana-kids',
    Title: 'Indiana Kids',
    SupportEmail: 'support@outerlimitstech.com'
  }
};


export const FALL_BACK_CONFIG: IOltAppSettingResult<IAppSettings> = {
  served: false,
  withError: null,
  settings: {
      hosts: [
          {
              host: "localhost",
              apiEndpoint: environment.apiRoot,
              environment: "Development",
              production: false
          }
      ]
  }
};
